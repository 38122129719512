export const allowlistAddresses = [

  ["0xbda3b165918c3a5a705159547cc304be4a410925",1],
  ["0xfa99c5e78e5f8d0d367b15a0b5469cc15b0fbdae",1],
  ["0xc3ebdea05821dfa77a7b512aa2dc115162efaf16",1],
  ["0xa4dbc734f92447cfa28aeaf8a74bc9c2558c9798",1],
  ["0x20ef8d8ea210ec7d74da03f753b2e63c39f230e4",1],
  ["0x2f7618af8125f4161a2973c932c0cf5e1033160a",1],
  ["0xa9f60c04751447f110d2a8c8b7a3b8ed329f671a",1],
  ["0xfbea77b50218b9c438722e30648b0acedcf52d41",1],
  ["0x2920f145cb72f64598e61eaf8c3080a662787586",1],
  ["0xf2230dd244c38ee78f6b5243b1d1c8ac38979e16",1],
  ["0x5648cf092f4b7c3155be7c9f3b4a96522fe71c79",1],
  ["0x7296344e96596541c6f555ebb1d5992ca071c220",1],
  ["0x2bf6943feb294b02c0607f099f9d0bfe9b13a29d",1],
  ["0x33c9e80e3c83eeed388d85498faa1c6d5d6eb3a5",1],
  ["0x75c4c5ed522454f1b0cd899d77fca9b5336dbabb",1],
  ["0x5ea87a5fbf34ff92a6ed06a4dbbcc6fe935e0a6e",1],
  ["0x34d02daf887acf5165bf0f17393abf1292090837",1],
  ["0x5ed0cb5f507cf82f5a1b84e715b040fa361c433b",1],
  ["0x0cfc2eebcea6ea51364ece20451d2546a89c4a56",1],
  ["0xb06e3887fdc6e7e2e6b820d1c4f51768248217ca",1],
  ["0xb2b2b940e3e8cdf2e9f9ebb8dbecb28fa3d473da",1],
  ["0x9025cae242fe2b21d0ec33e1d0c3ae22698f6d0e",1],
  ["0x2d5a1190b6c1bf13b256210a65f625681b488110",1],
  ["0x99b270bcfa68fcedbdf13886f940d71ca277b756",1],
  ["0x764b2df7351b7999857d605063c6d48cc9713252",1],
  ["0xcd6e6912af67481dee9e328a52c85241b255f095",1],
  ["0x36e18ab9da425d5b59de9176f19d867f8eb30b25",1],
  ["0xe693f386a965d8f87b72e43e46e756b362232253",1],
  ["0x273aed05309e3ee2fcb74ed87b7025e130004cbe",1],
  ["0x8b947e66cefb7c1bd66713af61d0ae4dc56095a3",1],
  ["0x437398f99c834cd2ab762dbc6e95eb99737a4e81",1],
  ["0x7ba8c9cc4b12f47c56a5c4138bb5c39f1051d901",1],
  ["0xf352c63c386b976ddab959e45ed9f79b65981363",1],
  ["0x0422859c2c4145232552446fa5f3801f5e16f57b",1],
  ["0x06d5ee53fa6c2596ef1d9e170c8e4dea841e62e3",1],
  ["0x13ee4ff1562abe44edf98fbc2c37995bcafec1e8",1],
  ["0x2b98a2257b00f5a9cd766b4339d59f3a36c08cbe",1],
  ["0x27a7a3355d2d51f413d7d73e069a17cb106f13be",1],
  ["0xb7d994657d400d434e84b11d02c935be69957657",1],
  ["0x2060e51b5a5fd724cff259a8f94297d6d210a790",1],
  ["0xc1eb9ba4fccb18984f5d082d7c05dd57984d10fd",1],
  ["0xb3bb620afa2f1bd474e8ccdb29baafee8c677174",1],
  ["0xfb69222fb028cff81deb79a4254c9e7e9b8fd878",1],
  ["0x7b00a75d376f6fe0abaa5f0408d723d74fc02f44",1],
  ["0x33adc71343c2adb836cd835c96385034ba333033",1],
  ["0xc145a9993a8db9407daf669c1ed857c072a30e92",1],
  ["0x8422075cf6daf10316ab867ce6b4db3d47d0458b",1],
  ["0x2a2f3d9a777038ce331ceeec14472cc6a37ccd62",1],
  ["0x8e389ff6a59f84e428d4e75d62c8b888f3c9e822",1],
  ["0x6bdcea88da2d7522f676a791159233d9e5386bb3",1],
  ["0x71c9513936110a213d7d67c5ba419efade78b866",1],
  ["0xe4a804f36720ce410a094cd3f73bcdbe36293f5d",1],
  ["0x18f1fb47fa1c983e9c7e6548cbf550243d880e41",1],
  ["0x37d32679ce9245a3f67d4830cbb3453e85e705d3",1],
  ["0x3aa3419475eca32efde41560de0135cf87c040ab",1],
  ["0x346086e2d09a5d5ab007b738b3c801826866e970",1],
  ["0x88191462ecb2219f93d5e8091b8d2311ddc96a62",1],
  ["0x50252ddec36de574734fe0cb4a475c7e6f1c2eb2",1],
  ["0x3b1cf67891753dcb5dd6271f3bf0520dc9cb2446",1],
  ["0xd5f6812971692117f581796147d2d3a2a579737c",1],
  ["0xe08969064200ad5c6ef6aba1b01d7c13a41ac4fa",1],
  ["0xf8592bcf093026aec6e587175da9361a040f67bd",1],
  ["0x2eae489d97fa92167d73857cc69eb900dbc05112",1],
  ["0x79265162896f5bb32df2c543dae6380aa82da8db",1],
  ["0xfa4c32e7f3d7a17450ff3cc363dd1188c9ac03d3",1],
  ["0x51eac84a5085e21712cc010bbd3ba657f43431dc",1],
  ["0x3020ec29a51bd2d0071e1941737a08a981fe6032",1],
  ["0xf6c465989b9bcb745cad0c9b96ede6bf21849bb7",1],
  ["0x95576b44757263150e1224da24d6a6a0edc81cd6",1],
  ["0x96afb2ff4ad20331c43082aee6f50a26ff68f29b",1],
  ["0xfcd49aa97b91c3575d36c95a1fe97dabec017890",1],
  ["0x5c18a8624de253bd7da4806589dc8fbca216cd8f",1],
  ["0xdfb5a2f8032d15a18673b16069c28434c97fd07d",1],
  ["0xf5b7eea559b2971605e310cc374e24fb95a5ec64",1],
  ["0xa7d72b48c609c36edf6eafe767daeb7b5248d5b3",1],
  ["0xd3c9dc346520919628a558b41343234f1829455e",1],
  ["0xa30144d2a0757aa4731220ac6550b63dff6ad3f5",1],
  ["0xea49b00d0a9178753f1f3fb7df03eef69a49352d",1],
  ["0x617694cf85abfcfb338d4751736e0fc27909c487",1],
  ["0xc303554eee2f994abe125aa9ce2075461b7f19d7",1],
  ["0xfecfefc100ae961359e23b9d40b6a7f5f9931f4b",1],
  ["0x44fe3d8b725d5793a406f83ceb98837121036c3d",1],
  ["0x2e2e495d6c145b45eeccf6a41bcb99d249fa3223",1],
  ["0x2a56a182338eab2096d0641a5f1196cde3939bcd",1],
  ["0xf52fea813fc0c2e58c39b5b889af3c426b4ed6aa",1],
  ["0x8dc863127527781b995fa0c41168638e398e9465",1],
  ["0xfb4a1208e44a8a3c548e0fe40a0b14d4e55630ee",1],
  ["0x73779d70fb456d5c2d4417574982f12e2c9beb3b",1],
  ["0x65b4f34469c5b06a45331f5121e16ee03077adfb",1],
  ["0x00bacf4a39cabd057dfbf3d3cf414f5f00451146",1],
  ["0xdfe4b644c10de399b32d6479b63af4e7e2a5cc18",1],
  ["0x3bd6950ae203fa9c8c48e1679fc16eaa85e693c9",1],
  ["0x4d9afe885d24a31ebd505d44736b9dc74ef519ad",1],
  ["0x3f09dfd844a489299451d6d3be5958f01547a24c",1],
  ["0x3a5788baaffdfc88a70b68ec9a1c6d122cde0df0",1],
  ["0x7faf3640ad5eba3e13373cdaac18c387a21b202d",1],
  ["0x8f2a7e5040d232eda9d672b57108986698321fbe",1],
  ["0xb4a4b42081ca39f07c62f0a3f4bee9687559d7a9",1],
  ["0xcead478a9927975e21f71926de9b8dccfbbc3c57",1],
  ["0xa61b2d37f919e94c763cb04b70b6a70e05e87d68",1],
  ["0x1c81d202c2323104645e37e098ddcf4a6b6b99f8",1],
  ["0x2c6e9bb001e26d83f4f2aa7669cbfc2a53a05f7a",1],
  ["0x3170df62b9c8cdaae487ade87c2d82f87073864b",1],
  ["0xd0e1706458197d3cb23f6744c43b2841d319aa3a",1],
  ["0x9f696d0c1617a3da22b1a77eb15df0d6d13cb3de",1],
  ["0x0058f3dbc0f909c8c372ed77c5acff22fa6ae7d1",1],
  ["0xa448c6b5d93e7481cd49e440d1fb7c928aaa1bae",1],
  ["0x75c4d7544dc881596e3679345a7e915f62171dea",1],
  ["0x165e59203ec3dda55aefa97822825bc41b89f96d",1],
  ["0x2a6041a3eca0473bad9417cbecd8f69fdc6a3377",1],
  ["0xebbf452f0b26e206a672c84b14bb1207bb1ec96f",1],
  ["0x14a96475f3fbc28572ce3d52539a9d07087c9384",1],
  ["0x16add04eaf42303cad0002c60de34c7d0c4372dc",1],
  ["0xc999e34971211f5d77b854d5aed4209c93f5a7db",1],
  ["0x5ac48cdeab05be808c47f69e1a3b27e3206097fd",1],
  ["0xac52569be91d2601d4927223353d62193fb52e2f",1],
  ["0xb211255a557a8eb2aad60ae5b9234595975b97c0",1],
  ["0xce520181607d93afb34e7aec6cf8ea766c4581ce",1],
  ["0x3a70e9dd8837cb34f4e4aa98ee732256b71cbb79",1],
  ["0xc5e97dd71bff9102d3ee9781de07c8cde8391b18",1],
  ["0xb19c1f56f8d2bf268921abc34beaddef5b32e6b6",1],
  ["0x6dca8483133a13fafc3621a66997756b51ed7792",1],
  ["0x33bd8e490b8bd555dbb793af4d28ee312417bacc",1],
  ["0x0d031e7a77cf9e85ce7a7afe6aba6113fb38a1bf",1],
  ["0xbb3abee4d3a3f6c890ea94a58cee213995334091",1],
  ["0x50a77cbf4a703937a7a2ad738981af89911dffb1",1],
  ["0x4f70cf0d20e082d83d9b9c910285d76748747609",1],
  ["0x05b0a16db546bf91113b9be5167c661035e5254a",1],
  ["0x583c9abae8f886490b9148d6a3c9b59b9efd0f58",1],
  ["0x3ce3dc50e0d6cbd72865aa73288023cfec41c66d",1],
  ["0x88c0a10a1887943dbdb7ffee32ec0eefdc657e82",1],
  ["0x4b116a04bf4491b5c7958e9d0388802e224f9efa",1],
  ["0x9b3335d2a1beead70e3c43b8b7050bcea245146d",1],
  ["0xcbda95216f36ae039e5dd855bc4fdc73a0c9db2a",1],
  ["0xa0c67f130d17870e7f32cf0b60f7fc5a0bc1baf3",1],
  ["0x2760ee2ceadb9d46416ab2df564907443b5501d7",1],
  ["0xe741a9e976c768e6811cdbd62ae24389f5352a1d",1],
  ["0xc8aeb1b28a86daba096a6e82303e30ee02b4e040",1],
  ["0xbd72edfa246e0ac71768632383b75ce172494dc0",1],
  ["0xca2a9da8cb194dd718c1ad34475736f3f134514e",1],
  ["0x6e28de65f3bf1c11bbed2efa22619e37454443de",1],
  ["0xb31650a68d79164afa7141ffabe288261a27c12e",1],
  ["0x6e6f24dd90653f7df1a7a1192a6a054fdec6cf80",1],
  ["0xd3959c06a50b8faa364a50e10b7021c0f97da554",1],
  ["0xc7c88dc0ef8390acdf3ee75af7b11db39884de43",1],
  ["0xa8e035f1d0ef3f9eea02688d1d64e7fdaa91970a",1],
  ["0x68871052fc7082f96f4ca473d730ce61a7515e26",1],
  ["0x7a28f32050db8329acd9ea0e47dcbb34acb360c5",1],
  ["0xd550c93631eb8736803fca8b59f44b9aeb531c62",1],
  ["0xed59125be371ac07c4383b54dcdbd8db2d5bd843",1],
  ["0x766f5f442fbcfe074e75c9f6bf2e564745d3c1a4",1],
  ["0x11d822d51a7cf3fe683462e22a57e7635dca3871",1],
  ["0xcc2de266b413e331770a6a2724f0ae3bcc498b36",1],
  ["0x0d6bc5c82945ec6771c92be7f571b5a2a0ae6cd9",1],
  ["0xf9bb8d8dba6fbfdc5affd598174780530cd97889",1],
  ["0x21a4cadbd32d7241f9891ca9feea9a3cf40ac29c",1],
  ["0xa3570a87be6133c59101bf06d6d4cf98fe77182e",1],
  ["0x45c4a819d5273bedebff7cacf91396c0ce8f1e64",1],
  ["0x798c962c105c024e8e55f022cbe006d3433a5770",1],
  ["0xa360dca46ebcf28c05053db4122e74b2ebce7d3d",1],
  ["0x47a991819ab1e4fb3a884b54f12baeb346f9dc69",1],
  ["0x5745b26b54dee310cce62c82c750fb0df64b5559",1],
  ["0xab5970d136f777ca4d06eca991ca068a635d124f",1],
  ["0xad505e847c671418393b77d6813bc9bcb8b831ea",1],
  ["0x75c0e835f3613bfb383ec2cd4f6743871daf0d3d",1],
  ["0x2099296f14173b27bbe49f9232d3ef95dfd1a259",1],
  ["0x0b0cb7fc08365a4388cec80ac0e31e4dd6dfa7ea",1],
  ["0x076d950e6f8bc52faad4151ae85b3bb5404a050b",1],
  ["0x2b123bb66c0a1173688e8367a13de1b24ca4781e",1],
  ["0xa832fc9af70f79c29f972c6dfd92e10846343b23",1],
  ["0x0f9831a88abb0ea6479f19af7ab6f53a3c490f72",1],
  ["0x70e4c174a97398551a46debe8b38a4193e59c5f8",1],
  ["0x031f4a4bcd4217e702233ec9a0cca00780693377",1],
  ["0x6782f8a51b0b8dcd7d22eff441c8094a9ad86831",1],
  ["0x494967f9df8521d175b292f09bb9471b6304f769",1],
  ["0x9a5194dbdeae262272b0a9fadf25be8b1a0f071b",1],
  ["0xc6b89a75307767d24e6134141b114f1c647968e7",1],
  ["0x0ba2d22a239fcd35f34491f2e93f4e4e10401b51",1],
  ["0xc05f6f502f3efde5bb094294639b3d31854f54ff",1],
  ["0x1565f1e817c07a3d77084fb11f02a24d716d0899",1],
  ["0x64b1862e35802e719b68a5086f8881e6b432f313",1],
  ["0x798ac982df12772ea11cf340b189631e4fe684dd",1],
  ["0xeed6c2d7dc0cf2e2211cbc424ba9884fbf1b23d0",1],
  ["0x4f3e516cbb3972fe752eb1c46a711aa2da1b14e7",1],
  ["0xee1dab8fb10a331d72a545958be0cd204aefe241",1],
  ["0xd02b085086b0af93ccf054fd28d42fafb78ef36d",1],
  ["0xad74a39a683f56c8e7cf731ecefa7587bc4bad0e",1],
  ["0x1b904e19727a4c61abc29eaa7690dcc5d2bc9426",1],
  ["0x9d02d0a772db0ee4989606ddeb2eff2294dfc520",1],
  ["0x0854d9b13f7c9071d8d9cb01daf927e4fefe8d99",1],
  ["0x485900c532c0720a3ce186e82b43dda598069aa5",1],
  ["0x7fc6c613c26445f629e47eae3e67eea2cd077104",1],
  ["0xdc93634373a463c9ef254cd2cc29b187ca1ee9fa",1],
  ["0xd77be56fccc6e129de620679cbbf493ac388ee06",1],
  ["0x0bfcc6baec1706b3ccf1695b2789a65d4fab3962",1],
  ["0x89255b3f71ad8a5a044448935d8433b17cb195a3",1],
  ["0x6458479b19c8cef6d254a2c6b86df5aec50f0df2",1],
  ["0x5b1407498c1d775f881dca1363e0b82e15e9acf1",1],
  ["0xccfb7b5e748a2e705db012a9bc616b89a3aa3c2c",1],
  ["0x0d630995bb14f8a4b3ece26dbd7d4c7c162a3f5c",1],
  ["0xa63afdf0002e087191729f0335f4df77b3c555c1",1],
  ["0x7f0dcb0c9f933440b8d7386d01e10047737b13d6",1],
  ["0x006f3f6ff35fa2c30584f55a039178d496f8e1c1",1],
  ["0x39b589eda76b8e1774f297cf4cfef335a558510a",1],
  ["0xf58cd278905422ac0c72bbe2ff13e90fd3b13995",1],
  ["0xc60ecb4b92d7d44d495ba2ac60645e316dd991ba",1],
  ["0xfd30a5b4c7c3abdc1c82cd4d61818f5a0a294631",1],
  ["0x513403811280992409911010adf1a7b4072f71e3",1],
  ["0x586be6c0f6e381e120680ca3945c6ea88aa1fe15",1],
  ["0xc1124f513e1ef19b0ae344808a188c87fb3fc0f9",1],
  ["0x79b674980154e579f194feda61cfb6c1dd789359",1],
  ["0xf38b60463ecc1281e7781dcb41c3e46952009272",1],
  ["0xfa95728513423547f951543d9983e626f0073a57",1],
  ["0x2bd8afa9bdfd2899326b833507bd4dd0bfd846df",1],
  ["0xb6a8473a8aef1af0a2e49b6db49926d7323b932d",1],
  ["0xeffa0b6b2ed1a91c14c281f23eabc4e2d7ff6fcf",1],
  ["0x5cd76758c27318e2e2c609a52e42a150a6969577",1],
  ["0xde314fa031a871e5aca135c1dd87c89c37a38f87",1],
  ["0x8cd762cfea0e2b2061eb7941baa530a3b3d544a1",1],
  ["0xb6a38c6e3a23091171977a9f6d310a8c50872bf9",1],
  ["0x667363aa2cf82c805664a998ea09a07a39820804",1],
  ["0xd8d920eb54398a2544be9504f456bc21fb17b443",1],
  ["0x7b4f0c06a979f45a883ec893364fbbf273f58fb2",1],
  ["0x4aaff54644911c13db70db2a41c1c87a0d6e5040",1],
  ["0x91efd32e53253cc56468981c1d13361ad0d5cb8d",1],
  ["0xac42dc8501e44439215e771c6fc05a4e8a59a9ec",1],
  ["0x31c6700c929c7c73df31751da4fb56fff127b840",1],
  ["0x1b365df8c62b0cec1316d9ea918898c4edcd1e6c",1],
  ["0x5a5cc9dab5cfcaaba2316d8628a745ac60eedd47",1],
  ["0xcb3efa2a094c974d4243f0b199f08216bd523e3b",1],
  ["0x41debb4704c681b403ba3b0f8e78e3fe2f29bcca",1],
  ["0x95c7244ab034eaab6a9c47bf47d3e6e4b4306ff0",1],
  ["0x8275d0d3ce7e7e2fdd2bbb80ca66c06da99b2c82",1],
  ["0xdd6e74c1b8e41b5e113e31f5618c3995427fa790",1],
  ["0xc740743cbbffabb69d28b678329977b575afc1dd",1],
  ["0xade197468baef98e5b3365539388731ad2b5644f",1],
  ["0x99dc62b11c0ab05cf17ac701929a903f219bf0f4",1],
  ["0xc3d3e3da141ecce9cb2098a7d44355b9f0b26ea7",1],
  ["0x81e5b98d4ca134d59337f8c62217cfdef9d107f0",1],
  ["0x74a8bd2f181eb73e55ef68ade10f42a0e6eed282",1],
  ["0xbc1cc73a06a0d8713a72ce0c95abf9805a31d450",1],
  ["0xedb6ae5c01b726032c7178642f84780fd8c17c25",1],
  ["0xd4686d35b9bd708675008ce45477209058c50cc1",1],
  ["0x32f2a7a720e7d1f8a4044ee358ae56fe4ac3e756",1],
  ["0x7548e5cf2503c1be6883450f9d09785481dc9aa2",1],
  ["0x70cff716197fe6f524325b8aaff54591434412d7",1],
  ["0x00ec112a9d8b542ad7fae99dc65b447322da267b",1],
  ["0x50fa235ab8df464ecfe8f4574c4eed7ae3bb0261",1],
  ["0xb21c04d933d1020189586c79de25b0426fc094be",1],
  ["0x9736a64c2425d95fc0824f9af0cf02b39ff5f7fb",1],
  ["0xf9c8bcf5f958586e50353cd6568aedb3564f4083",1],
  ["0xb376529bd298d9b5a1caa55f005a18c0452b662f",1],
  ["0x6b734e69d1680b7497fa87f59faeb91f80ee18d7",1],
  ["0x36ac4966e99623bd53c4f23e06454812691e98d7",1],
  ["0x93d9918055ed09b15cb7671a3da9d6bffea863c1",1],
  ["0x378f533b9188d92abbe5732420ba5758c3e37bba",1],
  ["0x2b547a82f549bebe2f59737f9bde6f7c70c295e7",1],
  ["0x76e5ab93840a50e47dfb8df417f76a6cd7b809be",1],
  ["0xcc6ae730ba628a81478e18b1c5e5b593f2c8d74e",1],
  ["0x2f0a075324da24ee49b0302c79dd15d91474ac4b",1],
  ["0xa91b4d9e136035a10cf18e16b43bb0b589010788",1],
  ["0xb2608b9e80d1b543e443647f0c07a2dbf49aee0b",1],
  ["0xbf2b4860386d01447fb7711c7d99596180a85b18",1],
  ["0x1f084ea143597e5380d65e6e3e94b68e5801eccf",1],
  ["0x379b9ca87f778aa5ba259c389533e50ea2181a9e",1],
  ["0xb2b4b4edbdadad67925cd8f9c2499a5e50b5bac1",1],
  ["0x4deb3604acac0518703009dc9da2310218a7d9e3",1],
  ["0xc3c848d9aa0c10613cd7c3adf5e38cee0edbe34a",1],
  ["0x89f360494251aa0f37700a4e9d935998d352a9f7",1],
  ["0xa74c8b9d4bb1385095703b29f8beab385ca34eb9",1],
  ["0xc0dc468bdd2126acf8d72dc6eb77949465784edb",1],
  ["0x89674047e16e3e5d00724032185b9ac2a14a570a",1],
  ["0xfd5b3bdcda40cf7d77f792b769a98cab1fabfa23",1],
  ["0x807731c7cd97321e7bf5649fa035af35b2731aac",1],
  ["0x453303b94a6a78d37c1eff561d8ac22731a2c07e",1],
  ["0x35bcd108136776b8bd95aac8317c01d84c5b3606",1],
  ["0x2e7d7e6676f5a4d1f9781eb7df285680a178364a",1],
  ["0xcf340b1e3278507d0a0c87d948c5e639423afc80",1],
  ["0x3d0a965088f4bd956127e61cfd6d9545f3713cbb",1],
  ["0xa2c4d35e6d5258f334ecc18b278e190ef671d699",1],
  ["0x89fc6d9f9fe66ce7b5c1a8d17542b96f98925a81",1],
  ["0x861fed36179aa125fa7578b46d7802f8c8590679",1],
  ["0x259f2eb800a0b3c99b7ad870aac916a13568ad33",1],
  ["0xa28455c05348b5b82b9e4f7b7efdfa0b405351cc",1],
  ["0xcaf0786ec1b0e39ba4fbe9a759a6e25bd084106d",1],
  ["0x07b8baf3866014b50d7e93fcbcd0ecc52dd2f6ab",1],
  ["0x68c99964b6149aac696efa5aae7518fc978054c8",1],
  ["0x1990bbc7bf55ca3836910bb8064af5aea1aa3990",1],
  ["0xf91894afedd4544b1cba3364f60751b1e60990dc",1],
  ["0x71d23d9e4e234fa47bcf95f5324c9bf304ed74dd",1],
  ["0xaf1a7d6e009c8bb5bf2aad4c49dd5d823cb1d2f3",1],
  ["0xcaaba3af17a31de56e272b021f74a16938181d01",1],
  ["0x426d911f8cff7668e57d8d953d181caa30b4ab0a",1],
  ["0x7058e7148ddfd06daf2803c76fdc7d7794178524",1],
  ["0xecf27ad8e8eb4ec9f76f68ebbe9c6c35a9c27970",1],
  ["0x41e4c689246b34b3acb20d6ba7956d55f2817034",1],
  ["0xd1acfaa3360f507eaeb7b3a14c59f6da57f8670b",1],
  ["0xfbbe0f22abecf94bbf1d7646544750fe31ee7021",1],
  ["0x60b075c173ba2a722747c4111694328638b297cb",1],
  ["0x1225a810e7bac6e7ebd0954e40e5c5b9fd62816a",1],
  ["0xf2466d553b1d0cdf6ede3e8c2d3652e7338d3c70",1],
  ["0x8db9918d3364fefa97d4ab1ff1fc18177e583df1",1],
  ["0xe3344dbe291d4906c3460f677120b4fff443d64f",1],
  ["0x952791bbb89c54a7f466ccd75ee050a880f56866",1],
  ["0x71b2950302e6ab5a8273302b4309a0f804d4b81c",1],
  ["0xf7abd16ec183c2c1f60f578ed9dff2d477c55d15",1],
  ["0x6dc6842bac430daf938f613fea96c13fd55296e0",1],
  ["0xcc39a3c122a54644956549f06907aa23534edfb0",1],
  ["0xdb028532be4fd86ea4640d4a39d38e031cce122e",1],
  ["0x2e422f4125be59bc6524c337f08f1b91be60693c",1],
  ["0x9e6a55b02772b73480406c9897907860b626dd42",1],
  ["0xba48bcf58941472fad93cbaf25e5862f02bd0eea",1],
  ["0x1dc45352a9d170fcbd44a8db2a5ac2dd7bc2f170",1],
  ["0xe00b46dffeecf08217312d32e69125d07987378f",1],
  ["0xdd04c0acd29b152492eb148a89bbcc7ce5271ddb",1],
  ["0xa5b977409d0762706bd57eeb88f7a5fcfcc7d5a6",1],
  ["0x2f617fa1c80bdeb85fc01c8dddd2fefd5a13a960",1],
  ["0x2d0574c4024f9a50ed7cdbf11d148848e2f7b551",1],
  ["0x8a15d4e2bc798165ef9f3b79ed78b32b92299125",1],
  ["0xc7d907517f810c55eafaf0ca3ba5136b3d1eeef6",1],
  ["0x239c26c75906f41598cefd951322f91025b60fd4",1],
  ["0x076f4f08bd30024a3bbc0118eb556379c57ee403",1],
  ["0xd43cb1a14db53492c6e06dc73b1deed1e8eb467d",1],
  ["0xed7715950262e220efef7ce8f38b9de51e4c0123",1],
  ["0x674bcf5edf37027cefa860fa8beb09f1f7edc6c6",1],
  ["0xf095fc7f1e5a471384ebaebb1fcd9461b3187bde",1],
  ["0xce0e4dd7d3829f403140dcc5b0e7aeab342f2ddf",1],
  ["0x7aa8baf0959b25d348aeb9fd4725da58a74ed7b5",1],
  ["0x1f03be0fe6a36e731e3d873a7397ba69217e4eae",1],
  ["0x1154078b9d59eab06994e7d27f7f65dbc033ef3a",1],
  ["0x7711e4b67465c55493a1ca9f4c1f0584014034a9",1],
  ["0x5f6ee3220f70cfa2fe974aaa8fea15305ed62e7a",1],
  ["0x3be09b2d176bf652fd45c7e7432d7be19126e6f5",1],
  ["0x1eca1a33d88842ac6dedef8537f5a32995f70974",1],
  ["0xc38febbaef96165a8fb75edac4a3ad3aac6f6386",1],
  ["0xca0f71dd70192437e1d8b5c36ecdf17c9c1e5aac",1],
  ["0x088f16334e70f557c45529560c1528677a086647",1],
  ["0x929197a25866f7a21ef450c76a70ba2f0ea5cedf",1],
  ["0x9aa64762527487325485f7ad808f60b810b323e7",1],
  ["0x7df273fa827d689e34a7a6a05932956e9ce66ba0",1],
  ["0xffe7f99312462fd85c0658b36e76c122e830a197",1],
  ["0x9799f4c900642889f50a9256fcd351efcbf3b925",1],
  ["0xb08a2ef5ed781ea54d2a142b57ec7e763e9fb025",1],
  ["0x988187f978c9630f3c558aa7d62fc44c9304bf12",1],
  ["0x5b87d81f1c6e980b13dd7297fe5a77388568d295",1],
  ["0x5e2ec01fdf7f8b6d14ca0329070f446e27e4c0ce",1],
  ["0xbcda14444aa860f0c673e5d73a18f0bd870ee343",1],
  ["0x188763feff652816a1c572d228a3a3cc84fc773d",1],
  ["0x5f6a2f3ea556896428e267f3dade8593fee98a0a",1],
  ["0x2240aa162dac9bfe1d8f349614c5c5611b1c9866",1],
  ["0x037060c831cafe2d6ff763420f79ced5cc32feda",1],
  ["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",1],

]